import { setup_tooltip } from "./../common_functions.js";
document.addEventListener("DOMContentLoaded", function () {
	$(document).ready(function () {
		$(".nav-item:not(.logged-in)").first().hide();
		// setting Help icon to navbar Helpdesk button
		$(".dropdown-toggle").html(
			'<svg fill="#000000" width="30px" height="30px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 6a3.939 3.939 0 0 0-3.934 3.934h2C10.066 8.867 10.934 8 12 8s1.934.867 1.934 1.934c0 .598-.481 1.032-1.216 1.626a9.208 9.208 0 0 0-.691.599c-.998.997-1.027 2.056-1.027 2.174V15h2l-.001-.633c.001-.016.033-.386.441-.793.15-.15.339-.3.535-.458.779-.631 1.958-1.584 1.958-3.182A3.937 3.937 0 0 0 12 6zm-1 10h2v2h-2z" fill="#000" style=""></path><path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z" fill="#000" style=""></path></svg>'
		);
		const path = window.location.pathname;
		if (
			path === "/trade-term-contract/list" ||
			path === "/freight-allowance-addendum-contract/list" ||
			path === "/vendor-data-services-contract/list" ||
			path === "/media-and-advertising-contract/list" ||
			path === "/autoship-and-save-contract/list"
		) {
			$(".web-sidebar .sidebar-items ul.list-unstyled:last li.sidebar-item:last").remove();
			setup_tooltip(path);
			const listContainer = $(".web-list-container");
			if (listContainer.length > 0) {
				const tabs = ["Action Needed", "Pending", "Completed", "Expired", "Rejected"];
				const newItem = $("<div>", { class: "rebate-contract-filters" });
				createTabs(tabs, newItem, "#workflow_state");
				listContainer.children().eq(0).after(newItem);
			}
		}
		// For SKU Request Change web list view
		else if (path === "/sku-request-change/list") {
			$(".page_content").css({ "max-width": "none" });
			$(".web-list-header").hide();
			setTimeout(() => {
				$('select[data-fieldname="vendor_status"]').prepend(
					$("<option>", {
						value: "",
						text: "Status",
						disabled: true,
						selected: true,
						hidden: true,
					})
				);
				$('input[data-fieldname="submission_id"]')
					.val(JSON.parse(localStorage.getItem("cost_update_requests")))
					.trigger("change");
			}, 500);
			const listContainer = $(".web-list-container");
			createActions(listContainer);
			setupCheckboxListener();
			// Use MutationObserver to monitor DOM changes and reapply event listeners
			const observer = new MutationObserver(function (mutationsList) {
				for (let mutation of mutationsList) {
					if (mutation.type === "childList") {
						// Reapply event listeners when DOM changes
						setupCheckboxListener();
					}
				}
			});

			// Observe changes to the list container
			observer.observe(listContainer[0], { childList: true, subtree: true });
		}
		// For Cost Update Request web list view
		else if (path === "/cost-update-request/list") {
			$(".page_content").css({ "max-width": "none" });
		}
		setTimeout(() => {
			if (
				path === "/trade-term-contract/list" ||
				path === "/freight-allowance-addendum-contract/list" ||
				path === "/vendor-data-services-contract/list" ||
				path === "/media-and-advertising-contract/list" ||
				path === "/autoship-and-save-contract/list"
			) {
				// Rearrange the filter fields based on the new order
				["supplier_name", "supplier_id", "trade_terms_type", "start_date", "end_date"].forEach(
					(fieldName) => {
						const $filter = $(`.web-list-filters .form-group[data-fieldname='${fieldName}']`);
						$(".web-list-filters").append($filter);
					}
				);
				addPlaceHolderToSelectField("trade_terms_type", "Trade Term Type");
				addPlaceHolderToSelectField("vendor_contract_status", "Vendor Contract Status");
				$(".rebate-contract-filters").find("button:first").trigger("click");
			}
		}, 500);
	});
});

function createTabs(tabs, container, targetField) {
	const buttons = tabs.map((text) => $("<button>").text(text));
	container.html(buttons);

	container.on("click", "button", function () {
		setup_tooltip(window.location.pathname);
		const tabText = $(this).text().toLowerCase().replace(" ", "_");
		$(this).addClass("selected").siblings().removeClass("selected");
		$(targetField).val(tabText);
		const dropdown = $(`[data-fieldname="${targetField.substring(1)}"] select`);
		dropdown.val(tabText).trigger("change");
	});

	$(document).on("change", `[data-fieldname="${targetField.substring(1)}"] select`, function () {
		const selectedValue = $(this).val();
		const tab = container.find("button").filter(function () {
			return $(this).text().toLowerCase().replace(" ", "_") === selectedValue;
		});
		tab.addClass("selected").siblings().removeClass("selected");
	});
}

function addPlaceHolderToSelectField(fieldName, placeholderText) {
	let $selectField = $(`select[data-fieldname="${fieldName}"]`);
	if ($selectField.length) {
		$selectField.prepend(`<option value="" disabled selected>${placeholderText}</option>`);
	}
}

function createActions(listContainer) {
	const helpdeskIcon = $(".navbar-nav .nav-item.dropdown").first().detach();
	let html = `
		<div id="actions-container" style="height:45px;">
			<div style="height:100%; display:inline-block; background-color: transparent;">
                <a href="/manage_costs"><button class="tab-button" id="request-cost-update" style="color:grey; background-color: transparent; border: none; cursor: pointer;">Request Cost Update</button></a>
            </div>
            <div style="height:100%; display:inline-block; background-color: transparent;" id="default-tab">
                <a href="/manage_costs#history-tab"><button class="tab-button" id="cost-update-history" style="color:grey; background-color: transparent; border: none; cursor: pointer;">Cost Update History</button></a>
            </div>
			<div id="helpdesk"  style="display:inline-block;"></div>
			<div class="dropdown-cu" style="display:none; float: right;">
				<button>Actions</button>
				<div class="dropdown-cu-content">
					<button id="download-btn">Download</button>
					<button>Cancel Request</button>
				</div>
			</div>
		</div>
	`;

	listContainer.children().eq(0).after(html);
	$("#helpdesk").append(helpdeskIcon);
	$(helpdeskIcon).css({
		display: "inline-block",
	});
	// jQuery to handle button activation and dropdown functionality
	$("#actions-container button.tab-button").on("click", function () {
		// Remove active state (color and border) from all buttons and divs
		$("#actions-container button.tab-button").css({
			color: "grey",
			"background-color": "transparent",
		});
		$("#actions-container div").css({
			"border-bottom": "none",
		});

		// Add active state (black color for button and blue underline for div) to the clicked button and its parent div
		$(this).css({
			color: "black",
			"background-color": "transparent", // optional, can add background if needed
		});

		$(this).closest("div").css({
			"border-bottom": "3px solid #0d6efd", // Apply blue underline to div
		});
	});

	// Set the default tab style (black color for the button and blue underline for its parent div)
	$("#cost-update-history").css({
		color: "black",
	});
	$("#default-tab").css({
		"border-bottom": "3px solid #0d6efd", // Default blue underline
	});
}

function setupCheckboxListener() {
	setTimeout(function () {
		// Select all row checkboxes
		const checkboxes = document.querySelectorAll('input[type="checkbox"]');

		checkboxes.forEach((checkbox) => {
			checkbox.addEventListener("change", handleSelectionChange);
		});
		$("#download-btn").on("click", downloadSkus);
	}, 2000);
}

function handleSelectionChange() {
	// Get all selected rows
	let selectedRows = [];
	const selectedCheckboxes = document.querySelectorAll(
		'.list-col-checkbox input[type="checkbox"]:checked'
	);

	selectedCheckboxes.forEach((checkbox) => {
		// Get the parent row ID (from the <tr id="...">)
		let row = checkbox.closest("tr");
		let recordId = row.getAttribute("id");
		selectedRows.push(recordId);
	});

	// Run your custom function when records are selected
	processSelectedRecords(selectedRows);
	return selectedRows;
}

function processSelectedRecords(selectedRecords) {
	if (selectedRecords.length > 0) {
		$(".dropdown-cu").css({ display: "block" });
	} else {
		$(".dropdown-cu").css({ display: "none" });
	}
}

function downloadSkus() {
	window.open(
		"/api/method/erpnext_custom_app.www.manage_costs.index.download_excel?skus=" +
			JSON.stringify(handleSelectionChange())
	);
}
